import * as React from "react";

import {
  Checkbox,
  Form,
  Input,
  Button,
  Space,
  Typography,
  notification,
} from "antd";

import { MailOutlined, LockOutlined } from "@ant-design/icons";

import * as firebase from "firebase/app";
import "firebase/auth";

const { Text } = Typography;

interface Props {
  register: () => void;
  forgotPW: () => void;
  resendVerification: () => void;
}

export const LoginForm: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = React.useState(false);

  const openErrorNotification = (description: string) => {
    notification.error({
      message: "Login Failed",
      description,
    });
  };

  const onFinish = (values: Record<string, string>): void => {
    setLoading(true);
    const persistence = values.remember
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    const { email, password } = values;

    firebase
      .auth()
      .setPersistence(persistence)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((uc: firebase.auth.UserCredential) => {
            if (uc.user != null) {
              if (!uc.user.emailVerified) {
                openErrorNotification(
                  "Please verify your email before proceeding."
                );
                uc.user.sendEmailVerification().then(() => {
                  props.resendVerification();
                });
              }
            }
          })
          .catch((error) => {
            const errorCode = error.code;
            switch (errorCode) {
              case "auth/invalid-email":
                openErrorNotification(
                  "Looks like that email is invalid. Please try with a valid email"
                );
                break;
              case "auth/user-disabled":
                openErrorNotification(
                  "This account was not recognized. Please try again later or contact support."
                );
                break;
              case "auth/user-not-found":
              case "auth/wrong-password":
                openErrorNotification(
                  "This email and password combination was not recognized. Please try with a valid email and password."
                );
                break;
              default:
                openErrorNotification(
                  "Server Error: Please try again later or contact support."
                );
                break;
            }
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(`Error: ${errorMessage} (${errorCode})`);
        openErrorNotification(
          "Server Error: Login Failed. Please try again later or contact support."
        );
      });
    setLoading(false);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: false,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input a valid email",
          },
        ]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input a valid password",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Button
          type="link"
          id="login-form-forgot"
          onClick={() => props.forgotPW()}
        >
          Forgot password
        </Button>
      </Form.Item>
      <Form.Item className="center-text">
        <Button
          type="primary"
          htmlType="submit"
          className="form-button"
          loading={loading}
        >
          Log in
        </Button>
      </Form.Item>

      <div className="center-text">
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Text>New user?</Text>
          <Button
            type="default"
            className="form-button"
            onClick={() => props.register()}
          >
            Register
          </Button>
        </Space>
      </div>
    </Form>
  );
};
