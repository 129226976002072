import * as React from "react";

import { Result } from "antd";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const ForgotCheckEmailForm: React.FC<Props> = () => (
  <Result
    status="success"
    title="Check your email for a password reset link."
    subTitle="You will be able to reset your password and access the portal after. Be sure to check your spam inbox."
  />
);
