import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import { createBrowserHistory } from "history";

import { UserContext, useAuth } from "../utils/firebase";

import { Login } from "./Login/Login";
import { MainPage } from "./MainPage/MainPage";
import { Action } from "./Action/Action";

export const App: React.FC = () => {
  const { user, verified } = useAuth();

  // const history = createBrowserHistory();

  return (
    <UserContext.Provider value={{ user, verified }}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/p">
            <MainPage />
          </Route>
          <Route exact path="/_action" component={Action} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
};
