/* eslint-disable no-nested-ternary */
import * as React from "react";

import { Avatar, Layout, Menu, Space, Spin, Button } from "antd";
import {
  BarChartOutlined,
  FormOutlined,
  HistoryOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

import * as firebase from "firebase/app";
import "firebase/auth";

import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store";

import hiprLogo from "../../images/logo_light.png";

import darkHiprLogo from "../../images/logo_dark.png";

import "./MainPage.less";

import { Patients } from "./Patients/Patients";
import { Patient } from "./Patient/Patient";

const { Content, Footer, Sider } = Layout;

const { SubMenu } = Menu;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface IState {
  collapsed?: boolean;
}

export const MainPage: React.FC<Props> = () => {
  const [collapsed, setCollapsed] = React.useState(false);

  const { path } = useRouteMatch();
  const history = useHistory();

  const [user, loading] = useAuthState(firebase.auth());

  const onCollapse = (toggle: boolean): void => {
    setCollapsed(toggle);
  };

  const firstName = String(
    useSelector((state: RootState) => state.User.firstName)
  );
  const lastName = String(
    useSelector((state: RootState) => state.User.lastName)
  );

  const dispatch = useDispatch<Dispatch>();

  React.useEffect(() => {
    dispatch.User.setUser(user || null);
    if (user) {
      user.getIdToken().then((idToken) => {
        dispatch.User.getPatients(idToken);
      });
    }
  }, [user]);

  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          breakpoint="xl"
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "sticky",
            top: 0,
            left: 0,
          }}
        >
          <div className="sider-div">
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
              <div className="logo">
                <Link to="/p">
                  <img
                    className="logo-img"
                    src={hiprLogo}
                    alt="HiPR Innovation Logo"
                    width="75%"
                  />
                </Link>
              </div>
              <Menu.Item key="1" icon={<BarChartOutlined />}>
                Patients
              </Menu.Item>
              <Menu.Item key="2" icon={<FormOutlined />}>
                Forms
              </Menu.Item>
              <Menu.Item key="3" icon={<HistoryOutlined />}>
                History
              </Menu.Item>
            </Menu>

            <Menu
              className="user-menu"
              theme="dark"
              mode="inline"
              selectable={false}
            >
              <SubMenu
                title={
                  <span>
                    <Avatar className="user-avatar">
                      {`${firstName[0]}${lastName[0]}`.toUpperCase()}
                    </Avatar>
                    <span className="user-text">{`${firstName} ${lastName[0]}.`}</span>
                  </span>
                }
              >
                <Menu.Item>Account Settings</Menu.Item>
                <Menu.Item
                  onClick={() => {
                    firebase.auth().signOut();
                  }}
                >
                  Logout
                </Menu.Item>
              </SubMenu>
            </Menu>
            <Button
              shape="circle"
              type="primary"
              style={{
                position: "fixed",
                left: collapsed ? 65 : 185,
                top: collapsed ? 50 : 80,
              }}
              icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
              onClick={() => onCollapse(!collapsed)}
            />
          </div>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ overflow: "initial" }}>
            <div className="site-layout-content-wrapper">
              {loading ? (
                <div className="spinner-wrapper center-text">
                  <Space direction="vertical" size="large">
                    <img
                      className="logo-img"
                      src={darkHiprLogo}
                      alt="HiPR Innovation Logo"
                      width="20%"
                    />
                    <Spin size="large" />
                  </Space>
                </div>
              ) : !user?.emailVerified ? (
                <Redirect to="/" />
              ) : (
                <Switch>
                  <Route exact path={path}>
                    <Patients />
                  </Route>
                  <Route path={`${path}/patient/:patientId`}>
                    <Patient />
                  </Route>
                  <Route path={`${path}/a/:patientId`}>
                    <Patient />
                  </Route>
                </Switch>
              )}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>HiPR Innovation ©2020</Footer>
        </Layout>
      </Layout>
    </div>
  );
};
