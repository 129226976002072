import * as React from "react";

import { Form, Input, Button, Space, notification, Typography } from "antd";

import { MailOutlined } from "@ant-design/icons";

import * as firebase from "firebase/app";
import "firebase/auth";

const { Text, Paragraph } = Typography;

interface Props {
  cancel: () => void;
  success: () => void;
}

export const ForgotForm: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = React.useState(false);

  /* ReCaptcha setup */
  const [captchaReady, setCaptchaReady] = React.useState(false);
  const recaptchaRef = React.createRef<HTMLDivElement>();
  let recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  /* rematch setup */

  React.useEffect(() => {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      recaptchaRef.current,
      {
        size: "normal",
        callback: () => {
          setCaptchaReady(true);
        },
      }
    );
    recaptchaVerifier.render();
  }, []);

  const openErrorNotification = (
    description: string,
    error?: Error | string
  ) => {
    if (error !== undefined) {
      console.log(error);
    }
    notification.error({
      message: "Password Reset Error",
      description,
    });
  };

  const onFinish = (values: Record<string, string>) => {
    setLoading(true);
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(() => {
        props.success();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        switch (errorCode) {
          case "auth/user-not-found":
          case "auth/invalid-email":
            openErrorNotification(
              "Something is wrong that email. Please check and try again."
            );
            break;
          default:
            openErrorNotification(
              "Server error. Please try again in a bit or contact support.",
              errorMessage
            );
            break;
        }
      });
    setLoading(false);
  };

  return (
    <Form
      name="forgot-form"
      className="register-form"
      initialValues={{
        remember: false,
      }}
      onFinish={onFinish}
    >
      <Space direction="vertical" className="center-text">
        <Paragraph>
          Enter your HiPR Portal <Text strong>email address</Text> that you used
          to register. We&apos;ll send you an email with a confirmation code to
          reset your password.
        </Paragraph>
        <Form.Item
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input a valid email",
            },
          ]}
          style={{ width: "75%", margin: "0 auto" }}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>

        <div className="center-text">
          <div
            ref={recaptchaRef}
            id="hi"
            style={{ padding: "16px", display: "inline-block" }}
          />
          <Form.Item>
            <Button
              className="form-button"
              type="primary"
              htmlType="submit"
              id="register-button"
              loading={loading}
              disabled={!captchaReady}
            >
              Send Confirmation Email
            </Button>
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              className="form-button"
              type="default"
              danger
              htmlType="button"
              onClick={() => props.cancel()}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Space>
    </Form>
  );
};
