/* eslint-disable import/no-cycle */
import { createModel } from "@rematch/core";

import * as firebase from "firebase/app";
import "firebase/auth";

import { RootModel } from ".";

import { register } from "../api/register";
import { fetchPatients, fetchPatientData } from "../api/patients";

export interface UserState {
  email: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  phoneNumber: Nullable<string>;
  patients: Patient[];
}

export const User = createModel<RootModel>()({
  state: {
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    patients: [],
  } as UserState,
  reducers: {
    setEmail: (state: UserState, email: Nullable<string>): UserState => ({
      ...state,
      email,
    }),
    clearEmail: (state: UserState) => ({ ...state, email: null }),
    setName: (state: UserState, name: Nullable<string>): UserState => {
      if (name !== null) {
        const nameSplit = name.split(", ");
        return { ...state, firstName: nameSplit[1], lastName: nameSplit[0] };
      }
      return state;
    },
    clearName: (state: UserState) => ({
      ...state,
      firstName: null,
      lastName: null,
    }),
    setPhoneNumber: (state: UserState, phoneNumber: Nullable<string>) => ({
      ...state,
      phoneNumber,
    }),
    clearPhoneNumber: (state: UserState) => ({ ...state, phoneNumber: null }),
    setPatients: (state: UserState, patients: Patient[]) => ({
      ...state,
      patients,
    }),
    clearPatients: (state: UserState) => ({ ...state, patients: [] }),
  },
  effects: (dispatch) => ({
    /**
     * Sends user ID token and form values for backend DB validation
     *
     * @param {Record} values Form values from RegisterForm and ID Token from Firebase
     */
    async registerUser(values: Record<string, string>): Promise<void> {
      const { fname, lname, specialty, facility, email, idToken } = values;

      const data = {
        fname,
        lname,
        specialty,
        facility,
        email,
      };

      // call backend api with ID token + values and use response to resolve or reject
      return register(data, idToken);
    },
    async setUser(user: firebase.User | null): Promise<void> {
      if (user === null) {
        dispatch.User.clearEmail();
        dispatch.User.clearName();
        dispatch.User.clearPhoneNumber();
      } else {
        const { email, displayName, phoneNumber } = user;
        dispatch.User.setEmail(email);
        dispatch.User.setName(displayName);
        dispatch.User.setPhoneNumber(phoneNumber);
      }
    },
    async getPatients(idToken: string): Promise<void> {
      fetchPatients(idToken).then((patients: Patient[]) => {
        dispatch.User.setPatients(patients);
      });
    },
    async getPatientData(id: string): Promise<PatientData> {
      return fetchPatientData(id);
    },
  }),
});
