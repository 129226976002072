/* eslint-disable no-nested-ternary */
import * as React from "react";

import Chart from "react-apexcharts";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const dummySeatedData = [
  ["2020-08-03", 16.3],
  ["2020-08-04", 11.2],
  ["2020-08-05", 10.0],
  ["2020-08-06", 3.0],
  ["2020-08-07", 5.2],
  ["2020-08-08", 5.6],
  ["2020-08-09", 17.5],
  ["2020-08-10", 18.2],
  ["2020-08-11", 23.3],
  ["2020-08-12", 21.1],
  ["2020-08-13", 19.3],
  ["2020-08-14", 11.2],
  ["2020-08-15", 6.7],
  ["2020-08-16", 0.3],
  ["2020-08-17", 13.3],
  ["2020-08-18", 15.2],
  ["2020-08-19", 3.6],
  ["2020-08-20", 16.3],
  ["2020-08-21", 7.5],
  ["2020-08-22", 9.2],
  ["2020-08-23", 5.3],
  ["2020-08-24", 16.7],
  ["2020-08-25", 0.3],
];

const dummyRiskData = [
  ["2020-08-03", 1.3],
  ["2020-08-04", 3.3],
  ["2020-08-05", 3.2],
  ["2020-08-06", 1.0],
  ["2020-08-07", 0.2],
  ["2020-08-08", 0.6],
  ["2020-08-09", 0.0],
  ["2020-08-10", 6.3],
  ["2020-08-11", 12.2],
  ["2020-08-12", 9.3],
  ["2020-08-13", 1.3],
  ["2020-08-14", 4.2],
  ["2020-08-15", 1.2],
  ["2020-08-16", 0],
  ["2020-08-17", 0.1],
  ["2020-08-18", 5.3],
  ["2020-08-19", 2.2],
  ["2020-08-20", 0.3],
  ["2020-08-21", 0.1],
  ["2020-08-22", 0],
  ["2020-08-23", 0.3],
  ["2020-08-24", 4.4],
  ["2020-08-25", 0.1],
];

export const SeatedArea: React.FC = () => {
  const [seatedData, setSeatedData] = React.useState(dummySeatedData);

  const [riskData, setRiskData] = React.useState(dummyRiskData);

  return (
    <div>
      <Chart
        options={{
          chart: {
            id: "seated-avg-area",
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              show: true,
            },
          },
          yaxis: {
            min: 0,
            max: 24,
            tickAmount: 4,
            title: {
              text: "Seated Time",
            },
          },
          tooltip: {
            enabled: true,
            shared: true,
            y: {
              formatter(val: string) {
                return `${val} hrs`;
              },
            },
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: "Daily Wheelchair Seated Totals",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          colors: ["#2E93fA", "#e54b4b"],
        }}
        series={[
          {
            name: "Seated Time",
            data: seatedData,
          },
          {
            name: "Risk Time",
            data: riskData,
          },
        ]}
        type="area"
        width="100%"
        height="400"
      />
      <Chart
        options={{
          chart: {
            id: "seated-avg-area-brush",
            brush: {
              target: "seated-avg-area",
              enabled: true,
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date(seatedData[0][0]).getTime(),
                max: new Date(seatedData[seatedData.length - 1][0]).getTime(),
              },
            },
          },
          stroke: {
            curve: "smooth",
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "datetime",
            tooltip: {
              enabled: false,
            },
            labels: {
              show: true,
            },
          },
          yaxis: {
            min: 0,
            max: 24,
            tickAmount: 2,
          },
          colors: ["#2E93fA", "#e54b4b"],
        }}
        series={[
          {
            name: "Seated",
            data: seatedData,
          },
          {
            name: "Risk",
            data: riskData,
          },
        ]}
        type="area"
        height="100"
        width="100%"
      />
    </div>
  );
};
