import * as React from "react";

import {
  Form,
  Input,
  Button,
  Tooltip,
  Select,
  Space,
  notification,
} from "antd";

import { PasswordInput } from "antd-password-input-strength";

import * as firebase from "firebase/app";
import "firebase/auth";

import { useDispatch } from "react-redux";

import { Dispatch } from "../../../store";

interface Props {
  cancel: () => void;
  registered: () => void;
}

const { Option } = Select;

export const RegisterForm: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = React.useState(false);

  /* ReCaptcha setup */
  const [captchaReady, setCaptchaReady] = React.useState(false);
  const recaptchaRef = React.createRef<HTMLDivElement>();
  let recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  /* rematch setup */
  const dispatch = useDispatch<Dispatch>();

  React.useEffect(() => {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      recaptchaRef.current,
      {
        size: "normal",
        callback: () => {
          setCaptchaReady(true);
        },
      }
    );
    recaptchaVerifier.render();
  }, []);

  const openErrorNotification = (description: string, error?: Error) => {
    if (error !== undefined) {
      console.log(error);
    }
    notification.error({
      message: "Account Creation Failure",
      description,
    });
  };

  const onFinish = (values: Record<string, string>) => {
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((uc: firebase.auth.UserCredential) => {
        if (uc.user != null) {
          const { user } = uc;
          user.getIdToken().then((idToken) => {
            dispatch.User.registerUser({ ...values, idToken })
              .then(() => {
                user
                  .updateProfile({
                    displayName: `${values.lname}, ${values.fname}`,
                  })
                  .then(() => {
                    user
                      .sendEmailVerification()
                      .then(() => {
                        props.registered();
                      })
                      .catch(() => {
                        openErrorNotification(
                          "Server Error. Please try refreshing and trying again."
                        );
                      });
                  });
              })
              .catch(() => {
                openErrorNotification(
                  "Server Error. Please try again or contact support."
                );
              });
          });
        } else {
          openErrorNotification(
            "Server Error. Please try again or contact support."
          );
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        switch (errorCode) {
          case "auth/email-already-in-use":
            openErrorNotification(
              "Looks like you already have an account. Please try logging in."
            );
            break;
          case "auth/invalid-email":
            openErrorNotification(
              "Something is wrong with your email. Please check and try again."
            );
            break;
          case "auth/weak-password":
            openErrorNotification(
              "Your password was too weak. Please try again with a stronger password."
            );
            break;
          case "auth/operation-not-allowed":
            openErrorNotification(
              "Server Error. Please try again or contact support."
            );
            break;
          default:
            openErrorNotification(
              "Server error. Please try again in a bit or contact support.",
              errorMessage
            );
            break;
        }
      });
    setLoading(false);
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="register-form"
      className="register-form"
      initialValues={{
        remember: false,
      }}
      onFinish={onFinish}
    >
      <div className="data-input">
        <Form.Item
          label="First Name"
          name="fname"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input a first name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lname"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input a last name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Medical Role"
          name="specialty"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please select your practitioner specialty",
            },
          ]}
        >
          <Select>
            <Option value="administrator">Administrator</Option>
            <Option value="doctor">Doctor</Option>
            <Option value="nursepractitioner">Nurse Practitioner</Option>
            <Option value="occupationtherapist">Occupational Therapist</Option>
            <Option value="physicaltherapist">Physical Therapist</Option>
            <Option value="physicianassistant">Physician Assistant</Option>
            <Option value="physician">Physician</Option>
            <Option value="registerednurse">Registered Nurse</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>

        <Tooltip
          trigger={["focus"]}
          title={"Please input the facility you're associated with"}
          placement="right"
        >
          <Form.Item
            label="Facility"
            name="facility"
            hasFeedback
            rules={[
              {
                required: true,
                message:
                  "Please input the facility/hospital you're associated with",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Tooltip>

        <Form.Item
          label="Email"
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input a valid email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Password must be at least 6 characters long",
              min: 6,
            },
          ]}
        >
          <PasswordInput />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirm-password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(rule, value: string) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error("Passwords do not match."));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </div>
      <div className="center-text">
        <Space direction="vertical">
          <div
            ref={recaptchaRef}
            id="hi"
            style={{ paddingBottom: "16px", display: "inline-block" }}
          />
          <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
            <Button
              className="form-button"
              type="primary"
              htmlType="submit"
              id="register-button"
              loading={loading}
              disabled={!captchaReady}
            >
              Register
            </Button>
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 0, span: 24 }}
            style={{ marginBottom: "0px" }}
          >
            <Button
              className="form-button"
              type="default"
              danger
              htmlType="button"
              onClick={() => props.cancel()}
            >
              Cancel
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};
