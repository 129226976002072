/* eslint-disable no-nested-ternary */
import * as React from "react";

import Chart from "react-apexcharts";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const Heatmap: React.FC = () => {
  return (
    <Chart
      options={{
        chart: {
          id: "heatmap",
          zoom: {
            enabled: false,
          },
        },
        legend: {
          position: "bottom",
        },
        subtitle: {
          text: "Front",
          margin: 0,
          floating: true,
          offsetY: 20,
          align: "center",
          style: {
            fontWeight: "bold",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          followCursor: true,
        },
        title: {
          text: "Heatmap",
          align: "left",
        },
        xaxis: {
          type: "category",
          labels: {
            show: false,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          title: {
            text: "Back",
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBoarder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 5,
            colorScale: {
              ranges: [
                {
                  from: -1,
                  to: -1,
                  name: "unknown",
                  color: "#a8a8a8",
                },
                {
                  from: 0,
                  to: 10,
                  name: "low",
                  color: "#005b96",
                },
                {
                  from: 10,
                  to: 50,
                  name: "medium",
                  color: "#ffa070",
                },
                {
                  from: 50,
                  to: 100,
                  name: "high",
                  color: "#e54b4b",
                },
              ],
            },
          },
        },
      }}
      series={[
        {
          name: "1",
          data: [-1, 3, 88, 6],
        },
        {
          name: "2",
          data: [0, 30, 73, 1],
        },
        {
          name: "3",
          data: [3, 5, 42, 33],
        },
        {
          name: "4",
          data: [-1, -1, 67, 100],
        },
      ]}
      type="heatmap"
      height="500"
    />
  );
};
