import * as React from "react";
import qs from "qs";

import { notification, Typography } from "antd";

import * as firebase from "firebase/app";
import "firebase/auth";

const { Paragraph, Text } = Typography;

interface Props {
  error: (description: string) => void;
  success: () => void;
  code: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined; // to appease TS
  email: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined; // to appease TS
}

export const VerifyForm: React.FC<Props> = (props: Props) => {
  const { code } = props;

  const openErrorNotification = (description: string) => {
    notification.error({
      message: "Password Reset Error",
      description,
    });
    props.error(description);
  };

  React.useEffect(() => {
    firebase
      .auth()
      .applyActionCode(String(code))
      .then(() => {
        props.success();
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/expired-action-code":
          case "auth/invalid-action-code":
            openErrorNotification(
              "Looks like your verify email link was expired/already used. Please login from homepage to receive another email verification."
            );
            props.error("Expired/Reused Verify Email");
            break;
          case "auth/user-disabled":
          case "auth/user-not-found":
            openErrorNotification(
              "Something is wrong with your account. Please try again or contact support."
            );
            props.error("Account Error");
            break;
          default:
            openErrorNotification(
              "Server error. Please try again in a bit or contact support."
            );
            break;
        }
      });
  }, []);

  const { email } = props;

  return (
    <div className="center-text">
      <Paragraph>
        Verifying Email: <Text strong>{`${String(email)}`}</Text>.
      </Paragraph>
    </div>
  );
};
