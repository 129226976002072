import axios from "axios";

export const register = (
  data: Record<string, string>,
  idToken: string
): Promise<void> => {
  return axios.post(`${process.env.REACT_APP_API_URL}/register`, data, {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};
