import * as React from "react";
import qs from "qs";

import "./Action.less";

import { Layout, Row, Col, Space, Typography, Result } from "antd";

import { RouteComponentProps } from "react-router-dom";

import { ResetForm } from "./forms/ResetForm";
import { VerifyForm } from "./forms/VerifyForm";

import hiprLogo from "../../images/logo_dark.png";

const { Text } = Typography;

const { Content } = Layout;

export enum status {
  pending = 1,
  error,
  success,
  reset,
  verify,
}

export const Action: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { location } = props;
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { mode, oobCode, apiKey, email } = params;

  const [formStatus, setFormStatus] = React.useState(status.pending);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if (
      mode === undefined ||
      oobCode === undefined ||
      apiKey !== process.env.REACT_APP_FIREBASE_API_KEY ||
      email === undefined
    ) {
      setFormStatus(status.error);
    }
    switch (mode) {
      case "resetPassword":
        setFormStatus(status.reset);
        break;
      case "verifyEmail":
        setFormStatus(status.verify);
        break;
      default:
        break;
    }
  }, [mode, oobCode, apiKey, email]);

  const getForm = () => {
    switch (formStatus) {
      case status.reset: {
        return (
          <ResetForm
            success={() => setFormStatus(status.success)}
            error={(desc: string) => {
              setFormStatus(status.error);
              setError(desc);
            }}
            code={oobCode}
            email={email}
          />
        );
      }
      case status.verify: {
        return (
          <VerifyForm
            success={() => setFormStatus(status.success)}
            error={(desc: string) => {
              setFormStatus(status.error);
              setError(desc);
            }}
            code={oobCode}
            email={email}
          />
        );
      }
      case status.error: {
        if (error === "") {
          return (
            <Result
              status="error"
              title="Your action credentials are incorrect or your link is expired."
              subTitle="Try logging in again on the homepage."
            />
          );
        }
        return (
          <Result
            status="error"
            title={`There was an issue with your authentication: ${error}`}
            subTitle="Try logging in again on the homepage."
          />
        );
      }
      case status.success: {
        switch (mode) {
          case "resetPassword":
            return (
              <Result
                status="success"
                title="Your password has successfully been reset."
                subTitle="You may now login with your new credentials."
              />
            );
          case "verifyEmail":
            return (
              <Result
                status="success"
                title="Your email has successfully been verified."
                subTitle="You may now login on the homepage."
              />
            );
          default:
            return <Result status="success" title="" subTitle="" />;
        }
      }
      default: {
        return <span />;
      }
    }
  };

  return (
    <Layout className="full-screen reset-background">
      <Content className="full-screen">
        <Row className="full-screen" align="middle" justify="center">
          <Col
            className="form-layout"
            xxl={8}
            xl={12}
            lg={12}
            md={16}
            sm={24}
            xs={24}
            flex={1}
          >
            <Space direction="vertical" size="small">
              <div className="logo">
                <img
                  className="logo-img"
                  src={hiprLogo}
                  alt="HiPR Innovation Logo"
                  width="40%"
                />
              </div>
              {getForm()}
            </Space>
            <div className="center-text" style={{ paddingTop: "24px" }}>
              <Space direction="vertical" size="small">
                <Text type="secondary" className="disclaimer">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </Text>
                <Text type="secondary" className="disclaimer">
                  © {new Date().getFullYear()} HiPR Innovation, LLC
                </Text>
              </Space>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
