export const fetchPatients = async (idToken: string): Promise<Patient[]> => {
  return [
    {
      name: "Jane Doe",
      id: "1",
      weightShiftCompliance: 80,
      weightShiftProtocol: {
        duration: { val: 20, unit: "sec" },
        frequency: { val: 30, unit: "min" },
      },
      dailySeatedAvg: 800,
    },
    {
      name: "John Smith",
      id: "2",
      weightShiftCompliance: 50,
      weightShiftProtocol: {
        duration: { val: 40, unit: "sec" },
        frequency: { val: 1, unit: "hr" },
      },
      dailySeatedAvg: 600,
    },
    {
      name: "Kevin Tran",
      id: "3",
      weightShiftCompliance: 100,
      weightShiftProtocol: {
        duration: { val: 20, unit: "sec" },
        frequency: { val: 45, unit: "min" },
      },
      dailySeatedAvg: 360,
    },
    {
      name: "Mary Gomez",
      id: "4",
      weightShiftCompliance: 30,
      weightShiftProtocol: {
        duration: { val: 20, unit: "sec" },
        frequency: { val: 50, unit: "min" },
      },
      dailySeatedAvg: 400,
    },
  ];
};

const patients: Record<string, PatientData> = {
  "1": {
    name: "Jane Doe",
    id: "1",
    weightShiftProtocol: {
      duration: { val: 20, unit: "sec" },
      frequency: { val: 30, unit: "min" },
    },
  },
  "2": {
    name: "John Smith",
    id: "2",
    weightShiftProtocol: {
      duration: { val: 40, unit: "sec" },
      frequency: { val: 1, unit: "hr" },
    },
  },
  "3": {
    name: "Kevin Tran",
    id: "3",
    weightShiftProtocol: {
      duration: { val: 20, unit: "sec" },
      frequency: { val: 45, unit: "min" },
    },
  },
  "4": {
    name: "Mary Gomez",
    id: "4",
    weightShiftProtocol: {
      duration: { val: 20, unit: "sec" },
      frequency: { val: 50, unit: "min" },
    },
  },
};

export const fetchPatientData = async (
  id: string
  // idToken: string
): Promise<PatientData> => {
  return patients[id];
};
