import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

interface UserContext {
  user: Nullable<firebase.User>;
  verified: boolean;
}

export const UserContext = React.createContext<UserContext>({
  user: null,
  verified: false,
});

export const useUser = (): Nullable<firebase.User> => {
  const { user } = React.useContext(UserContext);
  return user;
};

export const useVerified = (): boolean => {
  const { verified } = React.useContext(UserContext);
  return verified;
};

export const useAuth = (): UserContext => {
  const checkVerified = (user: Nullable<firebase.User>): boolean => {
    let verified;
    if (user === null) {
      verified = false;
    } else {
      verified = user.emailVerified;
    }
    return verified;
  };

  const [state, setState] = React.useState(() => {
    const user = firebase.auth().currentUser;
    const verified = checkVerified(user);
    return { user, verified };
  });

  const onChange = (user: Nullable<firebase.User>) => {
    const verified = checkVerified(user);
    setState({ user, verified });
  };

  React.useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return state;
};
