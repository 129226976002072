import * as React from "react";
import qs from "qs";

import { Form, Input, Button, Space, notification, Typography } from "antd";

import { PasswordInput } from "antd-password-input-strength";

import * as firebase from "firebase/app";
import "firebase/auth";

const { Paragraph, Text } = Typography;

interface Props {
  error: (description: string) => void;
  success: () => void;
  code: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined; // to appease TS
  email: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined; // to appease TS
}

export const ResetForm: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = React.useState(false);

  const openErrorNotification = (description: string) => {
    notification.error({
      message: "Password Reset Error",
      description,
    });
    props.error(description);
  };

  const onFinish = (values: Record<string, string>) => {
    setLoading(true);
    const fbCode = String(props.code);
    firebase
      .auth()
      .confirmPasswordReset(fbCode, values.password)
      .then(() => {
        props.success();
      })
      .catch((error) => {
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/expired-action-code":
          case "auth/invalid-action-code":
            openErrorNotification(
              "Looks like your reset code was expired/already used. Please try resetting again from the homepage."
            );
            props.error("Expired/Reused Reset Email");
            break;
          case "auth/user-disabled":
          case "auth/user-not-found":
            openErrorNotification(
              "Something is wrong with your account. Please try again or contact support."
            );
            props.error("Account Error");
            break;
          case "auth/weak-password":
            openErrorNotification(
              "Your password was too weak. Please try again with a stronger password."
            );
            break;
          default:
            openErrorNotification(
              "Server error. Please try again in a bit or contact support."
            );
            break;
        }
      });
    setLoading(false);
  };

  const { email } = props;

  return (
    <div className="center-text">
      <Paragraph>
        You are currently resetting the password for{" "}
        <Text strong>{`${String(email)}`}</Text>.
      </Paragraph>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="reset-form"
        className="reset-form"
        initialValues={{
          remember: false,
        }}
        onFinish={onFinish}
      >
        <div className="data-input">
          <Form.Item
            label="New Password"
            name="password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Password must be at least 6 characters long",
                min: 6,
              },
            ]}
          >
            <PasswordInput />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm-password"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value: string) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error("Passwords do not match."));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        <div className="center-text">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button
                className="form-button"
                type="primary"
                htmlType="submit"
                id="register-button"
                loading={loading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </div>
  );
};
