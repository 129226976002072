/* eslint-disable no-nested-ternary */
import * as React from "react";

import Chart from "react-apexcharts";

import moment from "moment";
import "moment-timezone";

interface Props {
  updateAverage: (avg: number) => void;
}

const dummyComplianceData = [
  ["2020-08-03T03:00:00", 65.3],
  ["2020-08-03T04:00:00", 76.7],
  ["2020-08-03T05:00:00", 90.3],
  ["2020-08-03T06:00:00", 93.3],
  ["2020-08-03T07:00:00", 53.2],
  ["2020-08-03T08:00:00", 53.6],
  ["2020-08-03T10:00:00", 97.5],
  ["2020-08-03T11:00:00", 88.2],
  ["2020-08-03T12:00:00", 65.3],
  ["2020-08-03T13:00:00", 76.7],
  ["2020-08-03T14:00:00", 90.3],
  ["2020-08-03T15:00:00", 93.3],
  ["2020-08-03T16:00:00", 76.7],
  ["2020-08-03T17:00:00", 60.3],
  ["2020-08-03T18:00:00", 63.3],
  ["2020-08-03T19:00:00", 53.2],
  ["2020-08-03T20:00:00", 53.6],
  ["2020-08-03T21:00:00", 66.3],
  ["2020-08-03T22:00:00", 70.5],
  ["2020-08-03T23:00:00", 32.2],
  ["2020-08-04T00:00:00", 65.3],
  ["2020-08-04T01:00:00", 76.7],
  ["2020-08-04T02:00:00", 60.3],
];

export const ComplianceLine: React.FC<Props> = (props: Props) => {
  const { updateAverage } = props;

  const [complianceData, setComplianceData] = React.useState(
    dummyComplianceData
  );

  const [complianceXAxis, setComplianceXAxis] = React.useState({
    min: complianceData[0][1],
    max: complianceData[complianceData.length - 1][1],
  });

  const [average, setAverage] = React.useState(0);

  const setAverageCompliance = (avg: number) => {
    setAverage(avg);
    updateAverage(avg);
  };

  const getAverage = (xaxis: { min: number; max: number }) => {
    let total = 0;
    let count = 0;
    for (let i = 0; i < complianceData.length; i += 1) {
      if (new Date(complianceData[i][0]).getTime() > xaxis.max) {
        break;
      }
      if (new Date(complianceData[i][0]).getTime() >= xaxis.min) {
        total += Number(complianceData[i][1]);
        count += 1;
      }
    }
    setAverageCompliance(Math.round(total / count));
  };

  return (
    <div>
      <Chart
        options={{
          chart: {
            id: "compliance-line",
            zoom: {
              enabled: false,
            },
            animations: {
              enabled: false,
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              show: true,
            },
            min: complianceXAxis.min,
            max: complianceXAxis.max,
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 4,
            title: {
              text: "Compliance (%)",
            },
          },
          annotations: {
            yaxis: [
              {
                y: average,
                borderColor: "#00E396",
                label: {
                  borderColor: "#00E396",
                  style: {
                    color: "#fff",
                    background: "#00E396",
                  },
                  text: "Average",
                },
              },
            ],
          },
          tooltip: {
            enabled: true,
            shared: false,
            x: {
              formatter(value: string) {
                return moment
                  .unix(Number(value) / 1000)
                  .utc()
                  .format("M/D ha");
              },
            },
            y: {
              formatter(val: string) {
                return `${val}%`;
              },
            },
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            text: "Hourly Average Weight Shift Compliance",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        }}
        series={[
          {
            name: "Compliance",
            data: complianceData,
          },
        ]}
        type="line"
        width="100%"
        height="400"
      />
      <Chart
        options={{
          chart: {
            id: "compliance-line-brush",
            brush: {
              target: "compliance-line",
              enabled: true,
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date(complianceData[0][0]).getTime(),
                max: new Date(
                  complianceData[complianceData.length - 1][0]
                ).getTime(),
              },
            },
            animations: {
              enabled: false,
            },
            events: {
              selection(
                chartContext: any,
                coord: {
                  xaxis: { min: number; max: number };
                  yaxis: { min: number; max: number };
                }
              ) {
                setComplianceXAxis(coord.xaxis);
                getAverage(coord.xaxis);
              },
            },
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            tooltip: {
              enabled: false,
            },
            labels: {
              show: true,
            },
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 2,
          },
          colors: ["#008FFB"],
        }}
        series={[
          {
            name: "Compliance",
            data: complianceData,
          },
        ]}
        type="line"
        height="100"
        width="100%"
      />
    </div>
  );
};
