import * as React from "react";

import { Result } from "antd";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const RegisterCheckEmailForm: React.FC<Props> = () => (
  <Result
    status="success"
    title="Your account has been created! Please verify your email!"
    subTitle="You will be granted access after you verify your email. Be sure to check your spam inbox."
  />
);
