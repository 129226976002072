/* eslint-disable no-nested-ternary */
import * as React from "react";
import "./Login.less";

import { Layout, Row, Col, Space, Typography, Spin } from "antd";

import { Redirect } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

import * as firebase from "firebase/app";
import "firebase/auth";

import { LoginForm } from "./forms/LoginForm";
import { RegisterForm } from "./forms/RegisterForm";
import { RegisterCheckEmailForm } from "./forms/RegisterCheckEmailForm";
import { ForgotForm } from "./forms/ForgotForm";
import { ForgotCheckEmailForm } from "./forms/ForgotCheckEmailForm";

import hiprLogo from "../../images/logo_dark.png";

const { Text } = Typography;

const { Content } = Layout;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export enum forms {
  login = 1,
  register,
  forgotPW,
  registerCheckEmail,
  forgotCheckEmail,
}

export const Login: React.FC<Props> = () => {
  const [formState, setFormState] = React.useState(forms.login);
  const [user, loading, error] = useAuthState(firebase.auth());

  const getForm = () => {
    switch (formState) {
      case forms.login: {
        return (
          <LoginForm
            register={() => setFormState(forms.register)}
            forgotPW={() => setFormState(forms.forgotPW)}
            resendVerification={() => setFormState(forms.registerCheckEmail)}
          />
        );
      }
      case forms.register: {
        return (
          <RegisterForm
            cancel={() => setFormState(forms.login)}
            registered={() => setFormState(forms.registerCheckEmail)}
          />
        );
      }
      case forms.forgotPW: {
        return (
          <ForgotForm
            cancel={() => setFormState(forms.login)}
            success={() => setFormState(forms.forgotCheckEmail)}
          />
        );
      }
      case forms.registerCheckEmail: {
        return <RegisterCheckEmailForm />;
      }
      case forms.forgotCheckEmail: {
        return <ForgotCheckEmailForm />;
      }
      default: {
        return <span />;
      }
    }
  };

  return loading ? (
    <div className="spinner-wrapper center-text">
      <Space direction="vertical" size="large">
        <img
          className="logo-img"
          src={hiprLogo}
          alt="HiPR Innovation Logo"
          width="20%"
        />
        <Spin size="large" />
      </Space>
    </div>
  ) : user?.emailVerified ? (
    <Redirect to="/p" />
  ) : (
    <Layout className="full-screen login-background">
      <Content className="full-screen">
        <Row className="full-screen" align="middle" justify="center">
          <Col
            className="form-layout"
            xxl={8}
            xl={12}
            lg={12}
            md={16}
            sm={24}
            xs={24}
            flex={1}
          >
            <Space direction="vertical" size="small">
              <div className="logo">
                <img
                  className="logo-img"
                  src={hiprLogo}
                  alt="HiPR Innovation Logo"
                  width="40%"
                />
              </div>
              {getForm()}
            </Space>
            <div className="center-text" style={{ paddingTop: "24px" }}>
              <Space direction="vertical" size="small">
                <Text type="secondary" className="disclaimer">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </Text>
                <Text type="secondary" className="disclaimer">
                  © {new Date().getFullYear()} HiPR Innovation, LLC
                </Text>
              </Space>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};
