/* eslint-disable no-nested-ternary */
import * as React from "react";

import { Input, List, Card, Typography, Progress } from "antd";

import { useSelector } from "react-redux";

import { useRouteMatch, useHistory } from "react-router-dom";

import { RootState } from "../../../store";

import "./Patients.less";

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface IState {
  collapsed?: boolean;
}

const gridStyle = {
  width: "50%",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
} as React.CSSProperties;

export const Patients: React.FC<Props> = () => {
  const patients = useSelector((state: RootState) => state.User.patients);

  const { path } = useRouteMatch();

  const history = useHistory();

  const goToPatient = (patient: Patient): void => {
    history.push(`${path}/patient/${patient.id}`);
  };

  const getComplianceColor = (percent: number | undefined): string => {
    if (!percent) {
      return "grey";
    }
    if (percent < 40) {
      return "red";
    }
    if (percent < 75) {
      return "orange";
    }
    return "green";
  };

  return (
    <Card
      title="Patients"
      className="patient-list-card"
      bordered={false}
      extra={<Input.Search placeholder="Search for Patient" />}
    >
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 2,
          xxl: 3,
        }}
        dataSource={patients}
        renderItem={(item) => (
          <List.Item>
            <Card
              className="patient-list-grid-card"
              title={item.name}
              extra={`ID: ${item.id}`}
              onClick={() => goToPatient(item)}
            >
              <Card.Grid hoverable={false} style={gridStyle}>
                <div className="patient-list-grid-content">
                  <Text strong>Weight Shifts:</Text>
                  <p className="patient-list-content-text">
                    {`Every ${item.weightShiftProtocol.frequency.val} ${item.weightShiftProtocol.frequency.unit} for ${item.weightShiftProtocol.duration.val} ${item.weightShiftProtocol.duration.unit}`}
                  </p>
                  <Progress
                    type="line"
                    strokeColor={getComplianceColor(item.weightShiftCompliance)}
                    percent={
                      item.weightShiftCompliance
                        ? item.weightShiftCompliance
                        : 0
                    }
                    size="small"
                    status="active"
                  />
                </div>
              </Card.Grid>
              <Card.Grid hoverable={false} style={gridStyle}>
                <div className="patient-list-grid-content">
                  <Text strong>Seated Average:</Text>
                  <p className="patient-list-content-text">{`${
                    item.dailySeatedAvg
                      ? `${Math.floor(item.dailySeatedAvg / 60)}h ${
                          item.dailySeatedAvg % 60
                        }m`
                      : "N/A"
                  }`}</p>
                </div>
              </Card.Grid>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

/* Unused format for patient list */
/* <List
    dataSource={patients}
    renderItem={(item) => (
      <List.Item
        actions={[
          <Button
            key="view-patient-button"
            type="link"
            onClick={() => goToPatient(item)}
          >
            View Patient
          </Button>,
          <Button key="edit-patient-button" type="link">
            Edit Info
          </Button>,
        ]}
      >
        <List.Item.Meta
          title={<Text strong>{item.name}</Text>}
          description={`Account ID: ${item.id}`}
        />

        <div className="patient-list-content">
          <Text strong>Weight Shifts:</Text>
          <p className="patient-list-content-text">{`Every ${item.weightShiftProtocol?.interval}m for ${item.weightShiftProtocol?.length}s`}</p>
          <Progress
            type="line"
            strokeColor={getComplianceColor(
              item.weightShiftCompliance
            )}
            percent={
              item.weightShiftCompliance
                ? item.weightShiftCompliance
                : 0
            }
            size="small"
            status="active"
          />
        </div>
        <div className="patient-list-content">
          <Text strong>Seated Average:</Text>
          <p className="patient-list-content-text">{`${
            item.dailySeatedAvg
              ? `${Math.floor(item.dailySeatedAvg / 60)}h ${
                  item.dailySeatedAvg % 60
                }m`
              : "N/A"
          }`}</p>
        </div>
      </List.Item>
    )}
  /> */
