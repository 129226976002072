/* eslint-disable no-nested-ternary */
import * as React from "react";

import {
  Card,
  Typography,
  Progress,
  PageHeader,
  Breadcrumb,
  Button,
  Row,
  Col,
  Space,
  Divider,
  DatePicker,
  InputNumber,
  Input,
  Select,
  Form,
} from "antd";

import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";

import { useDispatch } from "react-redux";

import { useParams, useHistory, Link } from "react-router-dom";

import moment from "moment";
import "moment-timezone";

import { Dispatch } from "../../../store";

import "./Patient.less";

import { Heatmap } from "./Charts/Heatmap";
import { SeatedArea } from "./Charts/SeatedArea";
import { ComplianceLine } from "./Charts/ComplianceLine";

const { Text } = Typography;

const { RangePicker } = DatePicker;

const { Option } = Select;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  collapsed?: boolean;
}

interface RouteParams {
  patientId: string;
}

export const Patient: React.FC = () => {
  const { patientId } = useParams<RouteParams>();

  const [averageCompliance, setAverageCompliance] = React.useState(0);

  const [patientData, setPatientData] = React.useState({
    weightShiftProtocol: { frequency: {}, duration: {} },
  } as PatientData);

  const dispatch = useDispatch<Dispatch>();

  const history = useHistory();

  const timeWithUnitsToString = (time: TimeWithUnits): string => {
    const str = `${String(time.val)}`;
    switch (time.unit) {
      case "sec":
        return `${str} seconds`;
      case "min":
        return `${str} minutes`;
      case "hr":
        return `${str} hours`;
      default:
        return "unknown";
    }
  };

  const [weightShiftFreq, setWeightShiftFreq] = React.useState(
    {} as TimeWithUnits
  );

  const [weightShiftDur, setWeightShiftDur] = React.useState(
    {} as TimeWithUnits
  );

  const onFinish = (values: Record<string, unknown>) => {
    console.log(values);
  };

  React.useEffect(() => {
    dispatch.User.getPatientData(patientId).then((data) => {
      setPatientData(data);
      setWeightShiftFreq(data.weightShiftProtocol.frequency);
      setWeightShiftDur(data.weightShiftProtocol.duration);
    });
  }, [patientId]);

  const disabledDate = (current: any): boolean => {
    return current > moment().endOf("day");
  };

  return (
    <Card
      className="patient-card"
      bordered={false}
      title={
        <div>
          <Breadcrumb className="patient-header-breadcrumb">
            <Breadcrumb.Item>
              <Link to="/p">
                <Button className="patient-header-breadcrumb-btn" type="text">
                  Patients
                </Button>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{patientData.name}</Breadcrumb.Item>
          </Breadcrumb>
          <PageHeader
            className="patient-header-page-header"
            title={patientData.name}
            onBack={() => history.push("/p")}
            extra={[
              <RangePicker
                key="picker"
                format="MM/DD/YYYY"
                defaultValue={[
                  moment("20200803T0000"),
                  moment("20200803T2359"),
                ]}
                disabledDate={disabledDate}
              />,
              <Button key="1" type="primary">
                Refresh
              </Button>,
            ]}
          />
        </div>
      }
    >
      <div>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card className="info-card">
              <Card.Meta description="Patient Info" />
              <div className="info-card-button-row">
                <Text className="info-card-title">{patientData.name}</Text>
                <Text className="info-card-text">{`(ID: ${patientData.id})`}</Text>
                <div className="info-card-button-group">
                  <Space
                    direction="horizontal"
                    align="center"
                    size="small"
                    style={{ marginTop: "8px", float: "right" }}
                  >
                    <Link to="/p" component={Typography.Link}>
                      View Patient Survey
                    </Link>
                    <Divider type="vertical" />
                    <Link to="/p" component={Typography.Link}>
                      Contact Patient
                    </Link>
                  </Space>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xl={8} lg={24} md={24} sm={24} xs={24}>
            <Card className="info-card">
              <Card.Meta description="Weight Shifts" />
              <div className="info-card-button-row">
                <Text className="info-card-title">{`Every ${timeWithUnitsToString(
                  patientData.weightShiftProtocol.frequency
                )}`}</Text>
                <Text className="info-card-text">{` for ${timeWithUnitsToString(
                  patientData.weightShiftProtocol.duration
                )}`}</Text>
              </div>
              <div className="info-card-row">
                <Text className="info-card-text">Average Compliance:</Text>
              </div>
              <Progress
                className="info-card-progress"
                type="line"
                strokeColor="green"
                percent={averageCompliance}
                size="default"
                status="active"
              />
              <Divider />
              <div className="info-card-data-row">
                <Text>
                  1w: 12% <CaretUpFilled style={{ color: "green" }} />
                </Text>
                <Divider type="vertical" />
                <Text>
                  10w: 5% <CaretDownFilled style={{ color: "red" }} />
                </Text>
              </div>
              <Divider />
              <div>
                <div style={{ marginBottom: "16px" }}>
                  <Text>Edit Recommendation:</Text>
                </div>
                <Form
                  name="edit-weight-shift"
                  labelAlign="left"
                  onFinish={onFinish}
                >
                  <Form.Item name="duration-group" label="Duration">
                    <Input.Group compact>
                      <InputNumber
                        min={1}
                        value={weightShiftDur.val}
                        onChange={(value) => {
                          setWeightShiftDur({
                            ...weightShiftDur,
                            val: Number(value),
                          });
                        }}
                      />
                      <Select
                        value={weightShiftDur.unit}
                        onChange={(value) => {
                          setWeightShiftDur({
                            ...weightShiftDur,
                            unit: value,
                          });
                        }}
                      >
                        <Option value="sec">seconds</Option>
                        <Option value="min">minutes</Option>
                      </Select>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item name="frequency-group" label="Frequency">
                    <Input.Group compact>
                      <InputNumber
                        min={1}
                        value={weightShiftFreq.val}
                        onChange={(value) => {
                          setWeightShiftFreq({
                            ...weightShiftFreq,
                            val: Number(value),
                          });
                        }}
                      />
                      <Select
                        value={weightShiftFreq.unit}
                        onChange={(value) => {
                          setWeightShiftFreq({
                            ...weightShiftFreq,
                            unit: value,
                          });
                        }}
                      >
                        <Option value="min">minutes</Option>
                        <Option value="hr">hours</Option>
                      </Select>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
          <Col xl={16} lg={24} md={24} sm={24} xs={24}>
            <Card className="info-card">
              <ComplianceLine updateAverage={setAverageCompliance} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xl={15} lg={12} md={24} sm={24} xs={24}>
            <Card className="info-card">
              <SeatedArea />
            </Card>
          </Col>
          <Col xl={9} lg={12} md={24} sm={24} xs={24}>
            <Card className="info-card">
              <Heatmap />
            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
